@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

@media only screen and (max-width: 430px)  {
  .App {
    text-align: center;
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: black;
  }

  .next-body {
      justify-content: center;
      text-align: center;
      align-items: center;
      background-color: #ffffff;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      color: black;
  }

  .hamburger {
    height: 18px;
    width: 21px;
    position: absolute;
    left: 85.6vw;
    top: 28px;
  }

  .App-header {
    position: relative;
    text-align: center;
    margin-top:52px;

  }
  .App-logo {
    height: 163px;
    pointer-events: none;
    top: 77px;
  }
  h1 {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-54px, 98px);
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    top: 0px;
  }
  .web-developer {
    left: 50%;
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    margin-top: 127px;
  }
  .mission {
    margin: auto;
    max-width: 244px;
    left: 50%;
    font-family: 'DM Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    margin-top: 45px;
  }
  .services {
    margin-top: 159px;
  }
  h2 {
    left: 50%;
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    top: 0px;
  }
  h3 {
    left: 50%;
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    top: 0px;
  }
  p {
    left: 50%;
    font-family: 'DM Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    top: 0px;
  }
  .down-arrow {
    left: 50%;
    margin: auto;
    height: 42px;
    width: 42px;
    margin-top: 42px;
    margin-bottom: 304px;
  }

  .services-list {
    width: 271px;
    align-items: center;
    display: grid;
    grid-template-columns: 222px 37px;
    grid-template-rows: auto auto auto;
    column-gap: 12px;
    justify-items: start;
  }

  .services {
    margin-top: 159px;
  }

  .service {
    text-align: left;
    justify-self:left;
  }

  .contact {
    margin-top: 141px;
    margin-bottom: 70px;
  }
  .contact-information {
    width: 151px;
    align-items: center;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 17px;
    justify-items: start;
    margin-bottom: 70px;
  }
  a {
    left: 50%;
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    top: 0px;
    text-decoration:underline;
    color: black;
    margin-bottom: 164px;
  }
}

@media only screen and (max-width: 1024px)  {
  .App {
    text-align: center;
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: black;
  }

  .next-body {
      justify-content: center;
      text-align: center;
      align-items: center;
      background-color: #ffffff;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      color: black;
  }

  .hamburger {
    height: 18px;
    width: 21px;
    position: absolute;
    left: 85.6vw;
    top: 88px;
  }

  .App-header {
    position: relative;
    text-align: center;
    margin-top:250px;

  }
  .App-logo {
    height: 163px;
    pointer-events: none;
    top: 77px;
  }
  h1 {
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-54px, 98px);
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    top: 0px;
  }
  .web-developer {
    left: 50%;
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    margin-top: 127px;
  }
  .mission {
    margin: auto;
    max-width: 244px;
    left: 50%;
    font-family: 'DM Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    margin-top: 45px;
  }
  .services {
    margin-top: 159px;
  }
  h2 {
    left: 50%;
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    top: 0px;
  }
  h3 {
    left: 50%;
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 18px;
    top: 0px;
  }
  p {
    left: 50%;
    font-family: 'DM Sans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    top: 0px;
  }
  .down-arrow {
    left: 50%;
    margin: auto;
    height: 42px;
    width: 42px;
    margin-top: 42px;
    margin-bottom: 817px;
  }

  .services-list {
    width: 271px;
    align-items: center;
    display: grid;
    grid-template-columns: 222px 37px;
    grid-template-rows: auto auto auto;
    column-gap: 12px;
    justify-items: start;
  }

  .services {
    margin-top: 439px;
  }

  .service {
    text-align: left;
    justify-self:left;
  }

  .contact {
    margin-top: 444px;
    margin-bottom: 70px;
  }
  .contact-information {
    width: 151px;
    align-items: center;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 17px;
    justify-items: start;
    margin-bottom: 70px;
  }
  a {
    left: 50%;
    font-family: 'Source Code Pro';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 20px;
    top: 0px;
    text-decoration:underline;
    color: black;
    margin-bottom: 407px;
  }
}
@media only screen and (min-width: 1024px)  {
.App {
  text-align: center;
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  color: black;
}

.next-body {
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    color: black;
}

.hamburger {
  height: 18px;
  width: 21px;
  position: absolute;
  left: 85.6vw;
  top: 65px;
}

.App-header {
  position: relative;
  text-align: center;
  margin-top:124px;

}
.App-logo {
  height: 163px;
  pointer-events: none;
  top: 77px;
}
h1 {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-54px, 98px);
  font-family: 'Source Code Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 30px;
  top: 0px;
}
.web-developer {
  left: 50%;
  font-family: 'Source Code Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  margin-top: 127px;
}
.mission {
  margin: auto;
  max-width: 244px;
  left: 50%;
  font-family: 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  margin-top: 45px;
  margin-bottom: 300px;
}
.services {
  margin-top: 159px;
}
h2 {
  left: 50%;
  font-family: 'Source Code Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  top: 0px;
}
h3 {
  left: 50%;
  font-family: 'Source Code Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  top: 0px;
}
p {
  left: 50%;
  font-family: 'DM Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  top: 0px;
}
.down-arrow {
  left: 50%;
  margin: auto;
  height: 42px;
  width: 42px;
  margin-top: 42px;
  margin-bottom: 514px;
}

.services-list {
  width: 271px;
  align-items: center;
  display: grid;
  grid-template-columns: 222px 37px;
  grid-template-rows: auto auto auto;
  column-gap: 12px;
  justify-items: start;
  margin-bottom: 
}

.services {
  margin-top: 159px;
}

.service {
  text-align: left;
  justify-self:left;
}

.contact {
  margin-top: 392px;
  margin-bottom: 70px;
}
.contact-information {
  width: 151px;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 17px;
  justify-items: start;
  margin-bottom: 70px;
}
a {
  left: 50%;
  font-family: 'Source Code Pro';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  top: 0px;
  text-decoration:underline;
  color: black;
  margin-bottom: 248px;
}
}